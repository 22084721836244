@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

@mixin theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $isDark: map-get($theme, is-dark);

  @include mtx.all-component-themes($theme);

  .mat-datepicker-content {
    background-color: mat.m2-get-color-from-palette($background, card) !important;
    filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.24));
  }

  @if $isDark {
    .mat-primary.mat-mdc-raised-button:not([disabled=true]), .mat-accent.mat-mdc-unelevated-button:not([disabled=true]) {
      color: black !important;
    }
  } @else {
    .mat-accent.mat-mdc-raised-button:not([disabled=true]), .mat-accent.mat-mdc-unelevated-button:not([disabled=true]), .mat-mdc-mini-fab.mat-accent:not([disabled=true]) {
      color: white !important;
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: white;
    }
  }


}

@mixin typography($theme) {

}

@mixin density($theme) {

}
