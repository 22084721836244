@use "variables";
@use '@angular/material' as mat;
@use 'palette' as palette;

@import "fonts";
@import 'utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();

@include init-theme();
