:root {
  --primary-50: #fff8e1;
  --primary-100: #ffecb3;
  --primary-200: #ffe082;
  --primary-300: #ffd54f;
  --primary-400: #ffca28;
  --primary-500: #ffc107;
  --primary-600: #ffb300;
  --primary-700: #ffa000;
  --primary-800: #ff8f00;
  --primary-900: #ff6f00;
  --primary-a100: #ffe57f;
  --primary-a200: #ffd740;
  --primary-a400: #ffc400;
  --primary-a700: #ffab00;

  --accent-50: #e3f2fd;
  --accent-100: #bbdefb;
  --accent-200: #90caf9;
  --accent-300: #64b5f6;
  --accent-400: #42a5f5;
  --accent-500: #28166f;
  --accent-600: #1e88e5;
  --accent-700: #1976d2;
  --accent-800: #1565c0;
  --accent-900: #0d47a1;
  --accent-a100: #82b1ff;
  --accent-a200: #448aff;
  --accent-a400: #2979ff;
  --accent-a700: #2962ff;

  --warn-50: #fce7eb;
  --warn-100: #f8c3cd;
  --warn-200: #f39bab;
  --warn-300: #ee7389;
  --warn-400: #ea5570;
  --warn-500: #e63757;
  --warn-600: #e3314f;
  --warn-700: #df2a46;
  --warn-800: #db233c;
  --warn-900: #d5162c;
  --warn-a100: #ffffff;
  --warn-a200: #ffd3d7;
  --warn-a400: #ffa0a8;
  --warn-a700: #ff8691;

  --contrast-50: rgba(black, 0.87);
  --contrast-100: rgba(black, 0.87);
  --contrast-200: rgba(black, 0.87);
  --contrast-300: rgba(black, 0.87);
  --contrast-400: white;
  --contrast-500: white;
  --contrast-600: white;
  --contrast-700: white;
  --contrast-800: white;
  --contrast-900: white;
  --contrast-a100: rgba(black, 0.87);
  --contrast-a200: rgba(black, 0.87);
  --contrast-a400: rgba(black, 0.87);
  --contrast-a700: rgba(black, 0.87);

  --font-family-sans: 'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"';
  --font-family-serif: 'ui-serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif';
  --font-family-mono: 'ui-monospace', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace';
  --font-family-default: 'Roboto', 'sans-serif';
  --font-family-mooli: 'Mooli', 'sans-serif';
  --font-family-inclusive-sans: 'Inclusive Sans', 'sans-serif';
  --font-family-fuggles: 'Fuggles', 'sans-serif';
  --font-family-pixelify-sans: 'Pixelify Sans', 'sans-serif';
  --font-family-roboto-mono: 'Roboto Mono', 'sans-serif';
  --font-family-croissant-one: 'Croissant One', 'sans-serif';
  --font-family-young-serif: 'Young Serif', 'sans-serif';
  --font-family: var(--font-family-default);
  --density: 0;

}

