@use '@angular/material' as mat;
@use 'palette' as palette;
@use 'custom' as custom;

@import "table";
@import 'loading-animation';

@mixin colors($theme) {
  $background: mat.m2-get-color-from-palette(map-get($theme, background), background);
  $foreground: mat.m2-get-color-from-palette(map-get($theme, foreground), text);

  --background-color: #{$background};
  --foreground-color: #{$foreground};

  --accept-color: rgb(115, 190, 105);
  --decline-color: rgb(204, 73, 51);

  @include mat.all-component-colors($theme);

  @include table-theme($theme);
  @include loadingAnimation($background);
}

@mixin density($theme) {
  @include mat.all-component-densities($theme);
  @include custom.density($theme);
}

@mixin typography($theme) {
  @include mat.all-component-typographies($theme);
  @include custom.typography($theme);
}

@mixin base-theme() {
  $theme: mat.m2-define-light-theme((
    color: (
      primary: mat.$m2-blue-palette,
      accent: mat.$m2-cyan-palette,
      warn: mat.$m2-red-palette
    ),
    typography: mat.m2-define-typography-config(
      $font-family: var(--font-family)
    ),
  ));
  @include mat.core-theme($theme);
  @include density($theme);
  @include typography($theme);

  body {
    background-color: var(--background-color);
    color: var(--foreground-color);

    &.density-2,
    &.density-3 {
      .mat-mdc-paginator-page-size {
        align-items: center;

        .mat-mdc-form-field-flex {
          height: 32px;

          .mat-mdc-form-field-infix {
            padding-top: 4px;

            .mat-mdc-select {
              margin-top: 0;
            }
          }
        }
      }

      .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) .mat-mdc-form-field-infix {
        padding-bottom: 0px;
      }

      .mat-mdc-floating-label {
        display: inline-block;
      }

      .mat-mdc-input-element, .mat-mdc-select {
        margin-top: 5px;
      }
    }

    .mat-mdc-snack-bar-container {
      --mdc-snackbar-container-color: #333333;
      --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
      --mat-snack-bar-button-color: #ffd740
    }

    .mat-mdc-form-field.mat-focused:not([appearance="outline"]) .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field:not(.mat-form-field-hide-placeholder):not([appearance="outline"]) .mat-mdc-form-field-icon-suffix {
      padding-top: 10px;
    }

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      max-width: calc(100% - 20px);
    }

    .mdc-notched-outline__notch {
      .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        max-width: 100%;
      }
    }

    @for $i from -3 through -1 {
      &.density#{$i} {
        @include density(mat.m2-define-light-theme((
          // https://m2.material.io/design/layout/applying-density.html#usage
          // between -3 and 0
          // density < -1 -> form field label not visible
          density: $i,
        )));
        --density: #{$i};
      }
    }
  }

}

@mixin body-theme($theme) {
  @include colors($theme);
  @include custom.theme($theme);
}

@mixin init-theme(
  $theme-primary: mat.m2-define-palette(palette.$primary-pat),
  $theme-accent: mat.m2-define-palette(palette.$accent-pat),
  $theme-warn: mat.m2-define-palette(palette.$warn-pat),
  $scope: null
) {

  $light-theme: mat.m2-define-light-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
  ));

  $dark-theme: mat.m2-define-dark-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
  ));

  // generate the base theme independent of the preferred color scheme
  @include base-theme();

  @if $scope {
    body.#{$scope} {
      @include body-theme($light-theme);

      &.dark, &.dark-theme {
        @include body-theme($dark-theme);
      }
    }
  } @else {
    body {
      @include body-theme($light-theme);

      &.dark, &.dark-theme {
        @include body-theme($dark-theme);
      }
    }
  }
}
