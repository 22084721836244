$helper-primary: #dadada;
$helper-secondary: #ececec;

$dark-helper-primary: #424242;
$dark-helper-secondary: #555555;
$dark-helper-secondary-alternative: #686868;


eurogard-helper {
  h2 {
    margin-bottom: 0.5rem !important;
  }

  ol {
    margin-bottom: 0.75rem;
    padding-left: 1.25rem;
    list-style-type: decimal;
  }

  ul {
    margin-bottom: 0.75rem;
    padding-left: 1.25rem;
    list-style-type: disc;
  }

  .grid.cards {
    display: none;

    ul {
      list-style-type: none;
      padding-left: 0;
      padding-top: 0.75rem;
    }
  }

  p > mat-icon, li > mat-icon {
    vertical-align: bottom;
  }

  table {
    max-width: 100%;
    width: fit-content;
    overflow-x: auto;
    display: block;
    border-collapse: collapse;
    background-color: #fafafa;
    margin-bottom: 0.75rem;
  }

  th {
    font-weight: bold;
    padding: 0.5rem;
    text-align: left;
  }

  td {
    padding: 0.5rem;

    mat-icon {
      scale: 0.75;
      vertical-align: bottom;
    }
  }

  tr {
    border-bottom: 1px solid #ccc;
  }

  table, tr {
    border: 1px solid #ccc;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  mat-icon {
    vertical-align: text-bottom;
  }

  h1 {
    font-size: 2rem !important;
  }

  h2 {
    font-size: 1.6rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
  }

  h4 {
    font-size: 1rem !important;
    font-weight: bold !important;
  }

  h5 {
    font-weight: bold !important;
    color: $dark-helper-secondary-alternative;
    text-transform: uppercase;
    font-size: 0.8rem !important;
  }

  h6 {
    font-weight: bold !important;
    color: $dark-helper-secondary-alternative;
    font-size: 0.8rem !important;
  }

  figcaption {
    text-align: center;
    font-style: italic;
  }

  h1, h2 {
    mat-icon {
      vertical-align: middle;
    }
  }

  h3, h4, h5, h6 {
    mat-icon {
      vertical-align: top;
    }
  }

  .mermaid {
    display: block;
    margin-bottom: 0.75rem;
  }

  hr {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid #ccc;
  }

  mat-icon.warn {
    color: #e63757;
  }

  mat-tab-group {
    font-size: 0.9rem;
  }

  &.dark-helper {
    table {
      background-color: $dark-helper-secondary;
    }

    tr:hover {
      background-color: $dark-helper-secondary-alternative;
    }

    hr {
      border-color: white;
    }

    h5, h6 {
      color: white;
    }

    .alert-container {
      border-color: $dark-helper-secondary-alternative !important;

      .alert-title {
        background-color: $dark-helper-secondary-alternative !important;
      }

      .alert-content {
        background-color: $dark-helper-secondary !important;
      }
    }
  }
}
